import { defineComponent as _defineComponent } from 'vue'
import AccountModule from "@/store/modules/Account";
import { Field, Form } from "vee-validate";
import { ElMessage } from "element-plus/lib/components/message";
import { useRoute, useRouter } from "vue-router";
import { object, string, ref as yupRef } from "yup";
import { computed } from "vue";
import { IUpdatePassword } from "@/models/User";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdatePassword.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();
const schema = object({
  Password: string()
    .required()
    .min(8)
    .max(64)
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/,
      "Your password must include a combination of numbers, upper, lower, case letters and symbols"
    )
    .label("Password"),
  ConfirmPassword: string()
    .required("Confirm Password is required")
    .oneOf([yupRef("Password"), null], "Your passwords are not the same"),
});

const email: any = computed(() => route.query["email"]);
const otpCode: any = computed(() => route.query["code"]);

const onSubmit = async (values: any) => {
  const data = {
    Email: email.value,
    Otp: otpCode.value,
    NewPassword: values.Password,
  } as IUpdatePassword;
  const dataResponse = await AccountModule.updatePassword(data);
  if (dataResponse) {
    router.push("/login");
  }
};

const __returned__ = { router, route, schema, email, otpCode, onSubmit, get AccountModule() { return AccountModule }, get Field() { return Field }, get Form() { return Form }, get ElMessage() { return ElMessage }, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get object() { return object }, get string() { return string }, get yupRef() { return yupRef }, computed, get IUpdatePassword() { return IUpdatePassword } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})