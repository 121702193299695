import { defineComponent as _defineComponent } from 'vue'
import { object, string } from "yup";
import { Field, Form } from "vee-validate";
import AccountModule from "@/store/modules/Account";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const emailTemp = ref<string>("");
const schemaEmail = object({
  Email: string().required("The email is required").email(),
});
const schemaOTP = object({
  OTP: string().required("OTP is required"),
});
const router = useRouter();
const onSubmitEmail = async (values: any) => {
  const data = await AccountModule.forgotPassword(values);
  if (data) {
    emailTemp.value = values.Email;
  }
};
const onSubmit = async (values: any) => {
  const data = {
    Email: emailTemp.value,
    Otp: values.OTP,
  };
  const dataResponse = await AccountModule.verifyOtp(data);
  if (dataResponse) {
    router.push({
      path: "/update-password",
      query: { email: data.Email, code: data.Otp },
    });
  }
};

const __returned__ = { emailTemp, schemaEmail, schemaOTP, router, onSubmitEmail, onSubmit, get object() { return object }, get string() { return string }, get Field() { return Field }, get Form() { return Form }, get AccountModule() { return AccountModule }, ref, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})