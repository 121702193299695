import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";

import store from "@/store";

import installElementPlus from "@/plugins/element-plus";
import installAOS from "@/plugins/aos";

import TheBlank from "@/components/Layouts/TheBlank.vue";
import TheDefault from "@/components/Layouts/TheDefault.vue";

import "animate.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/_base/index.css";
import "@/assets/styles/global.css";

import { vEllipsis } from "@/plugins/directive-ellipsis";

process.env.NODE_ENV === "development" &&
  document.getElementsByTagName("body")[0].classList.add("debug-screens");

const app = createApp(App);

installElementPlus(app);

installAOS();

app.component("blank-layout", TheBlank);
app.component("default-layout", TheDefault);

app.use(store);
app.use(router);

app.directive("ellipsis", vEllipsis);

app.mount("#app");

export default app;
