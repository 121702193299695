import store from "@/store";
import AccountModule from "@/store/modules/Account";
import PrivateRoutes from "@ems/pages/PrivateRoutes.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // progress bar style
import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteRecordRaw,
} from "vue-router";
import LoginPage from "@ems/pages/Login.vue";
import ForgotPassword from "@ems/pages/ForgotPassword.vue";
import UpdatePassword from "@ems/pages/UpdatePassword.vue";
import Authorize from "./pages/Authorize.vue";
import Routes from "./pages/Routes.vue";
import { OPS_ADMIN, CLIENT_ADMIN, TENANT_ADMIN } from "./constants";
import AccountPage from "@ems/pages/OfficeAdmin/AccountRegistration/index.vue";
import AccountDetail from "@ems/pages/OfficeAdmin/AccountRegistration/Details.vue";
import { STATUS_TENANT } from "./constants/account_registration_status";
import {
  clearAuthStorage,
  fetchEnvEms,
  getAccessToken,
  getRefreshToken,
  getRole,
  getTenantId,
  getTenantIsActive,
  getTenantStatus,
  logoutIdentity,
  setTenantStatus,
} from "@/utils/storage";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
  trickleSpeed: 800,
});

if (
  store.state.LocalStorage &&
  "token" in store.state.LocalStorage &&
  !!store.state.LocalStorage.token
) {
  AccountModule.loginSuccess(store.state.LocalStorage.token);
  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;
  if (isAuthenticated) {
    const tenantId = getTenantId() ?? "";
    AccountModule.loginIdentity(tenantId);
  }
}

const beforeEnterPublicPage = async (next: NavigationGuardNext) => {
  await fetchEnvEms();

  if (getAccessToken() !== null) {
    try {
      // Login directly to application
      await AccountModule.loginIdentity(getTenantId() ?? "");
      const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;

      const role = getRole();
      if (isAuthenticated) {
        const lastPath = localStorage.getItem("lastVisitedPath");
        if (lastPath) {
          return next({ path: lastPath });
        } else {
          return next({ replace: true, path: "/" });
        }
      } else {
        if (role === OPS_ADMIN) {
          next({ replace: true, path: "/admin" });
        } else {
          // Client login
          const isActive = getTenantIsActive();
          const status = getTenantStatus();

          if (isActive === "false") {
            next({ replace: true, path: "/tenant/create" });
          } else {
            if (parseInt(status!) !== STATUS_TENANT.Approved) {
              next({ replace: true, path: "/tenant/waiting-approval" });
            } else {
              const lastPath = localStorage.getItem("lastVisitedPath");
              if (lastPath) {
                return next({ path: lastPath });
              } else {
                return next({ replace: true, path: "/" });
              }
            }
          }
        }
        return;
      }
    } catch (error) {
      console.log(error);
      clearAuthStorage();
    }
  }
  next();
};

const view = (name: string) => () =>
  import(/* webpackChunkName: "[request]" */ `@ems/pages/${name}.vue`);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ROUTES = (name: string) => require(`@ems/pages/${name}/routes`).default;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      withAuth: true,
      permission: [TENANT_ADMIN],
      layout: "default",
      isApproved: true,
    },
    component: Routes,
    children: ROUTES(CLIENT_ADMIN),
  },
  {
    path: "/admin",
    name: "Admin Home",
    meta: {
      withAuth: true,
      permission: [OPS_ADMIN],
      layout: "default",
      requiresOpsAdmin: true,
      isApproved: true,
    },
    component: Routes,
    redirect: "/admin/account-registration",
    children: ROUTES(OPS_ADMIN),
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "blank" },
    component: LoginPage,
    beforeEnter: async (to, from, next) => {
      await beforeEnterPublicPage(next);
    },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { layout: "blank" },
    component: view("Logout"),
  },
  {
    path: "/redeem-invitation",
    name: "Redeem invitation",
    meta: { layout: "blank" },
    component: view("RedeemInvitation"),
  },
  {
    path: "/redeem-registration",
    name: "Redeem registration",
    meta: { layout: "blank" },
    component: view("Registration"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: view("ClientAdmin/Profile"),
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    meta: { layout: "blank" },
    component: ForgotPassword,
    beforeEnter: async (to, from, next) => {
      await beforeEnterPublicPage(next);
    },
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    meta: { layout: "blank" },
    component: UpdatePassword,
    beforeEnter: async (to, from, next) => {
      await beforeEnterPublicPage(next);
    },
  },
  {
    path: "/callback",
    name: "Callback",
    meta: { layout: "blank" },
    component: view("IdentityCallback"),
  },
  {
    path: "/authorize",
    name: "Authorize",
    meta: { layout: "blank" },
    component: Authorize,
  },
  {
    path: "/tenant",
    name: "TenantRegistration",
    meta: {
      withAuth: true,
      layout: "default",
    },
    component: Routes,
    children: [
      {
        path: "create",
        name: "TenantCreate",
        meta: { layout: "blank", isHome: true },
        component: view("ClientAdmin/Tenant/TenantRegistration"),
      },
      {
        path: "waiting-approval",
        name: "TenantWaitingApproval",
        meta: { layout: "blank", isHome: true },
        component: view("ClientAdmin/Tenant/TenantWaitingApproval"),
        props: (route) => ({
          showInvitations: route.query.showInvitations === "true",
        }),
      },
      {
        path: "select",
        name: "SelectTenant",
        meta: { layout: "blank" },
        component: view("ClientAdmin/Tenant/TenantCondition"),
      },
      {
        path: "invitations",
        name: "TenantInvitations",
        meta: { layout: "blank" },
        component: view("ClientAdmin/Tenant/TenantInvitations"),
      },
    ],
  },
  {
    path: "/:404(.*)*",
    name: "NotFound",
    meta: { layout: "blank" },
    component: view("NotFound"),
  },
];

const router = createRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return savedPosition || {};
    }

    return savedPosition || { top: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;
  const isActive = getTenantIsActive();
  const role = getRole();
  const tenantStatus = getTenantStatus();

  if (to.path !== "/login" && to.path !== "/logout") {
    localStorage.setItem("lastVisitedPath", to.fullPath);
  }

  if ("withAuth" in to.meta && to.meta.withAuth && !isAuthenticated) {
    if (getAccessToken()) {
      return next();
    }
    return next({ name: "Login", query: { redirect: to.fullPath } });
  }

  if ("requiresOpsAdmin" in to.meta && to.meta.requiresOpsAdmin) {
    if (role !== OPS_ADMIN) {
      // Prevent the user from accessing the route
      next(false);
    }
  }

  if ("isApproved" in to.meta && to.meta.isApproved) {
    if (role === TENANT_ADMIN) {
      if (isActive == "false") {
        return next({ name: "TenantCreate", query: { redirect: to.fullPath } });
      } else {
        if (parseInt(tenantStatus ?? "") !== STATUS_TENANT.Approved) {
          return next({
            name: "TenantWaitingApproval",
            query: { redirect: to.fullPath },
          });
        }
      }
    } else if (role === OPS_ADMIN) {
      if (!to.path.includes("admin")) {
        return next({ path: "/admin" });
      }
    }
  }

  if ("isHome" in to.meta && to.meta.isHome) {
    if (parseInt(getTenantStatus() ?? "") === STATUS_TENANT.Approved) {
      if (getRole() === TENANT_ADMIN) {
        setTenantStatus(STATUS_TENANT.Approved);
        return next({ replace: true, path: "/" });
      } else {
        setTenantStatus(STATUS_TENANT.Approved);
        return next({ replace: true, path: "/admin" });
      }
    }
  }

  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
