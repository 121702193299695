export enum STATUS_TENANT {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum STATUS_TIER_ONE {
  Pending = 0,
  Accepted = 1,
  Sent = 2,
  Removed = 3,
}

export enum STATUS_BRAND_OWNER {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
}

export enum STATUS_INVITATION {
  Inactive = 0,
  Activated = 1,
  Sent = 2,
  Pending = 3,
  Accepted = 4,
  Rejected = 5,
  Approved = 6,
}

export enum STATUS_LOG {
  Success = 0,
  Fail = 1,
}

export enum STATUS_REGISTER_CLIENT {
  InivitationSent = 2,
  Pending = 3,
  Rejected = 5,
  Approved = 6,
}
