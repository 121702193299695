import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['space-y-4 2xl:space-y-6'])
}
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = { class: "text-gray-600 text-base px-4" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    onSubmit: $setup.onSubmit,
    "label-position": "top"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", {
            class: "font-semibold hover:underline cursor-pointer",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.router.push('/forget-password')), ["prevent"]))
          }, " Forgot Password ")
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", null, [
          _createElementVNode("button", {
            type: "submit",
            class: "w-full btn btn--green-primary py-3 px-6"
          }, " Sign in ")
        ], -1)),
        _createElementVNode("p", _hoisted_3, [
          _cache[1] || (_cache[1] = _createTextVNode(" New to RESuite? ")),
          _createElementVNode("a", {
            href: $setup.href,
            class: "text-green-primary hover:text-green-700 hover:underline"
          }, "Sign up ", 8, _hoisted_4),
          _cache[2] || (_cache[2] = _createTextVNode(" here to continue. "))
        ])
      ])
    ]),
    _: 1
  }))
}