export const OPS_ADMIN = "OfficeAdmin";
export const CLIENT_ADMIN = "ClientAdmin";
export const CLIENT_USER = "ClientUser";
export const TENANT_ADMIN = "TenantAdmin";
export const UPSTREAM_PARTNER = "UpstreamPartner";
export const SUPPLYCHAIN_PARTNER = "SupplyChainPartner";

export enum RESUITE_ROLE {
  UpstreamPartner = 0,
  SupplyChainPartner = 1,
}
