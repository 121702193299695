import { defineComponent as _defineComponent } from 'vue'
import { clearAuthStorage, getAccessToken, getLoginUrl } from "@/utils/storage";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Authorize',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
onMounted(async () => {
  const redirectPath = route.query.redirect || "/";
  if (getAccessToken()) {
    clearAuthStorage();
  }
  const url = await getLoginUrl(`${redirectPath}`);
  window.location.href = url;
  const timer = setTimeout(() => {
    clearTimeout(timer);
  }, 200);
});

const __returned__ = { route, router, get clearAuthStorage() { return clearAuthStorage }, get getAccessToken() { return getAccessToken }, get getLoginUrl() { return getLoginUrl }, onMounted, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})