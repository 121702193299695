import { defineComponent as _defineComponent } from 'vue'
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import AccountModule from "@/store/modules/Account";
import SVGWorldMap from "@/assets/images/bg/world.svg";
import ForgotPasswordForm from "@ems/containers/Auth/ForgotPassword.Form.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

onMounted(() => {
  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;
  if (isAuthenticated) {
    router.push("/");
  }
});

const __returned__ = { router, onMounted, get useRouter() { return useRouter }, get AccountModule() { return AccountModule }, get SVGWorldMap() { return SVGWorldMap }, ForgotPasswordForm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})