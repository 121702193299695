import { OPS_ADMIN } from "@ems/constants";
import {
  ACCOUNT_REGISTRATION,
  ACTIVITY_LOG,
} from "@ems/constants/router_admin";
import Routes from "./Routes.vue";

const view =
  (name: string) =>
  (PERMISSION = OPS_ADMIN) =>
    import(
      /* webpackChunkName: "[request]" */ `@ems/pages/${PERMISSION}/${name}.vue`
    );
export default [
  {
    path: ACCOUNT_REGISTRATION.path,
    name: ACCOUNT_REGISTRATION.name,
    component: Routes,
    icon: "account_registration",
    children: [
      {
        path: "",
        name: ACCOUNT_REGISTRATION.name,
        component: view("AccountRegistration/index"),
      },
      {
        path: ACCOUNT_REGISTRATION.ACCOUNT_REGISTRATION_ID.path,
        name: ACCOUNT_REGISTRATION.ACCOUNT_REGISTRATION_ID.name,
        component: view("AccountRegistration/Details"),
      },
      {
        path: ACCOUNT_REGISTRATION.REGISTER_CLIENT.path,
        name: ACCOUNT_REGISTRATION.REGISTER_CLIENT.name,
        component: view("AccountRegistration/RegisterClient"),
      },
      {
        path: ACCOUNT_REGISTRATION.REGISTER_DETAIL_ID.path,
        name: ACCOUNT_REGISTRATION.REGISTER_DETAIL_ID.name,
        component: view("AccountRegistration/RegisterDetail"),
      },
      {
        path: ACCOUNT_REGISTRATION.VIEW_DETAIL_ID.path,
        name: ACCOUNT_REGISTRATION.VIEW_DETAIL_ID.name,
        component: view("AccountRegistration/ViewDetail"),
      },
    ],
  },
  {
    path: ACTIVITY_LOG.path,
    name: ACTIVITY_LOG.name,
    component: Routes,
    icon: "activity_log",
    children: [
      {
        path: "",
        name: ACTIVITY_LOG.name,
        component: view("ActivityLog/index"),
      },
    ],
  },
];
