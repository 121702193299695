import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { useRoute } from "vue-router";
import DialogModule from "@/store/UI/Dialog";
import { fetchEnvEms } from "@/utils/storage";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const layout = computed(() => `${route.meta.layout || "blank"}-layout`);
const Modal = computed(() => DialogModule.getModal);

fetchEnvEms();

const __returned__ = { route, layout, Modal, computed, get useRoute() { return useRoute }, get DialogModule() { return DialogModule }, get fetchEnvEms() { return fetchEnvEms } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})