import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-5 sm:mx-auto sm:w-full xl:max-w-4xl 2xl:max-w-3xl" }
const _hoisted_3 = { class: "p-10 bg-gray-secondary-whiten rounded-3xl bg-opacity-50" }
const _hoisted_4 = { className: "flex justify-center items-center mb-5" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "py-6 px-5 2xl:py-8 2xl:mt-12 shadow relative overflow-hidden bg-gray-secondary-whiten",
  style: { borderRadius: '20px' }
}
const _hoisted_7 = {
  class: "w-screen h-screen object-fill object-center bg-cover fixed -z-1 top-0 left-0",
  xmlns: "http://www.w3.org/2000/svg",
  style: {"background-color":"#fff"},
  fill: "#e5e5e5",
  stroke: "#000",
  "stroke-width": "1"
}
const _hoisted_8 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("h1", {
            className: "whitespace-no-wrap font-black text-4xl",
            style: {
              textShadow: '7px 3px 6px rgba(0, 0, 0, 0.2)',
            }
          }, " Welcome to ", -1)),
          _createElementVNode("img", {
            class: "h-10 px-3 mb-1 object-contain",
            src: require('@/assets/images/SJ-logo.png'),
            alt: "t-recs logo"
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["LoginForm"])
        ])
      ])
    ]),
    (_openBlock(), _createElementBlock("svg", _hoisted_7, [
      _createElementVNode("use", {
        "xlink:href": $setup.SVGWorldMap,
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }, null, 8, _hoisted_8)
    ]))
  ]))
}