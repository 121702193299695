export const SYSTEM = {
  platform_services_agreement: "Platform Services Agreement (PSA)",
  privacy_policy: "Privacy Policy",
  platform_fees: "Platform Fees",
  company_information: "Company Information",
  country: "Country",
  province: "Province",
  company_name: "Company Name",
  company_registration_number: "Company Registration Number",
  email: "Email",
  address: "Address",
  contact_number: "Contact Number",
  phone_code: "Phone Code",
  na: "N/A",
  registration_documents: "Registration Documents",
  company_registration_document: "Company Registration Document",
  letter_of_authorization: "Letter Of Authorization",
  other_relevant_documents: "Other Relevant Documents",
  required_documents: "Required Documents",
  action_type: "Action Type",
  user_name: "User Name",
  related_feature: "Related Feature",
  created_time: "Created Time",
  payload: "Payload",
  bulk_invite_file: "Bulk Invite File",
  register_client: "Register Client",
  register_detail: "Account Registration Detail",
  account_registrations: "Account Registrations",
  upstream_partner: "Upstream Partner",
  supplychain_partner: "Supply Chain Partner",

  // Title
  supply_chain_partner_company_information:
    "Supply Chain Partner Company Information",
  invite_supply_chain_partners: "Invite Supply Chain Partners",
};
