import { defineComponent as _defineComponent } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { getEnv, getLoginUrl } from "@/utils/storage";
import { onMounted, ref } from "vue";
import { Form } from "vee-validate";


export default /*@__PURE__*/_defineComponent({
  __name: 'Login.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();
const href = ref("");

const onSubmit = async (values: any) => {
  const redirectPath = route.query.redirect || "/";

  const url = await getLoginUrl(`${redirectPath}`);
  window.location.href = url;
  const timer = setTimeout(() => {
    clearTimeout(timer);
  }, 500);
};

onMounted(() => {
  const env = getEnv();
  href.value = `${
    env.VUE_APP_IDENTITY_URL
  }/Account/Register?ReturnUrl=${encodeURIComponent(
    `${env.VUE_APP_RESUITE_URL}/#/authorize`
  )}&ClientId=${env.VUE_APP_IDENTITY_CLIENT_ID}`;
});

const __returned__ = { router, route, href, onSubmit, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get getEnv() { return getEnv }, get getLoginUrl() { return getLoginUrl }, onMounted, ref, get Form() { return Form } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})